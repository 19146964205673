import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { useLanguageContext } from "../../contexts/language-context";
import { useLocation } from "@reach/router";

import transformTitle from "./transformTitle";
import makeMeta from "./makeMeta";
import transformSchema from "./transformSchema";
import makeLinkData from "./makeLinkData";

/**
 * The SEO Component utilizes ReactHelmet to inject all the metadata to the page for SEO purposes.
 *
 * @param {Object} props - The props object.
 * @param {string} props.title - The title of the page. Required.
 * @param {string} props.description - The description of the page. Required.
 * @param {string} props.imagePath - The og:image path. If not provided, the default image will be used.
 * @param {string} props.imageAlt - The alt text for the og:image. If not provided, the default alt text will be used.
 * @param {object} props.schema - The schema object.
 * @param {boolean} props.addSuffix - If true, the suffix " | WaFd Bank" will be added to the title. Default true.
 *
 * @returns {JSX.Element} - Returns the SEO component.
 */
const SEO = ({ title, description, imagePath, imageAlt, schema, addSuffix = true }) => {
  const transformedTitle = transformTitle(title, addSuffix);
  const path = useLocation().pathname.replace(/\/$/, ""); // Remove trailing slash (/) from the URL
  const isSpanish = useLanguageContext();

  const meta = makeMeta({ title: transformedTitle, description, imagePath, imageAlt, path, schema });
  const transformedSchema = transformSchema(schema);
  const linkData = makeLinkData(path, isSpanish);

  const hasSchema = Object.keys(transformedSchema).length !== 0;

  return (
    <Helmet
      htmlAttributes={{ lang: isSpanish ? "es" : "en" }}
      defaultTitle="WaFd Bank"
      title={transformedTitle}
      link={linkData}
      meta={meta}
    >
      {hasSchema && <script type="application/ld+json">{JSON.stringify(transformedSchema)}</script>}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  schema: PropTypes.object,
  addSuffix: PropTypes.bool
};

export default SEO;
