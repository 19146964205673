/**
 * transformTitle takes a title and a boolean to determine if the suffix should be ignored.
 * It returns the given title with the suffix | WaFd Bank.
 * It also sanitizes the title of any existing suffix if it's provided.
 *
 * @param {string} title - The title to transform.
 * @param {boolean} ignoreSuffix - Whether to ignore the suffix or not.
 * @returns {string} - Returns the transformed title.
 */
const transformTitle = (title, addSuffix) => {
  const baseTitle = title.replace(/ ?\| ?WaFd Bank/i, "");
  const suffixedTitle = baseTitle + " | WaFd Bank";

  if (addSuffix) {
    return suffixedTitle;
  } else {
    return baseTitle;
  }
};

export default transformTitle;
