import React from "react";
// Components
import SecondaryLanding from "../../../../layouts/secondary-landing";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import SEO from "../../../../components/seo/seo-new";

const FinancialNewsArticle2 = () => {
  const title = "WaFd Bank President and CEO Brent Beardall Expected to Return Soon to Full-Time Duties";
  const description =
    "Brent Beardall, President and CEO of Washington Federal, Inc. (Nasdaq: WAFD) and Washington Federal Bank (dba WaFd Bank), continues to make significant progress recovering from a small plane crash in early January in Provo, Utah.";

  const SEOData = {
    title,
    description,
    imagePath: "/images/adsearch/thumb-financial-news-02-250.jpg",
    imageAlt: "WaFd Bank's 40-year Nasdaq listing anniversary displayed in Times Square in New York City."
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      url: "/about-us/investor-relations",
      title: "Investor Relations"
    },
    {
      id: 3,
      url: "/about-us/investor-relations/financial-news",
      title: "Financial News"
    },
    {
      id: 4,
      active: true,
      title: "Brent Beardall Expected Return"
    }
  ];

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <h1>{title}</h1>
            <h3>January 26, 2023</h3>
            <p>
              Brent Beardall, President and CEO of Washington Federal, Inc. (Nasdaq: WAFD) and Washington Federal Bank
              (dba WaFd Bank), continues to make significant progress recovering from a small plane crash in early
              January in Provo, Utah. Beardall, who is undergoing extensive physical therapy at the Rehabilitation
              Hospital at the University of Utah Health, expects to be discharged from the hospital on February 10,
              2023, and return to his duties at WaFd Bank full time beginning February 13, 2023.
            </p>
            <p>
              &ldquo;I'm feeling stronger every day, lifted by so much love and support I've received over the last few
              weeks,&rdquo; Beardall said. &ldquo;I feel incredibly blessed to be on the mend so quickly and to work
              with an exceptional team of bankers who have stepped up and done an outstanding job running the bank
              during my recovery. With Executive Vice President and Chief Consumer Banker Cathy Cooper temporarily
              assuming my duties, we have not missed a beat executing on WaFd's growth strategies and we're pleased to
              post record quarterly earnings.&rdquo;
            </p>
            <p>
              Washington Federal's Chairman of the Board, Stephen Graham, said, &ldquo;We are so grateful to see Brent
              well on his way to a full recovery and we look forward to his return to normal duties after he completes
              inpatient rehabilitation.&rdquo;
            </p>
            <p>
              WaFd Bank, a federally insured Washington state-chartered bank with headquarters in Seattle, Washington,
              has more than 200 branches in nine western states. To find out more about WaFd Bank, please visit our
              website{" "}
              <a id="news-wafd-bank-link" href="/">
                www.wafdbank.com
              </a>
              . WaFd Bank uses its website to distribute financial and other material information about the Company.
            </p>
            <p>
              <strong>Contact:</strong>
              <br />
              Brad Goode
              <br />
              Chief Marketing & Communications Officer
              <br />
              WaFd Bank/Washington Federal, Inc.
              <br />
              425 Pike Street, Seattle, WA 98101
              <br />
              <a id="brad-goode-tel-link" href="tel:206-626-8178">
                (206) 626-8178
              </a>
            </p>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};
export default FinancialNewsArticle2;
