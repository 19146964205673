import { englishToSpanish, spanishToEnglish } from "../../data/url-alternates-data";
/**
 * @typedef {object} LinkDataObject
 * @property {string} rel
 * @property {string} href
 * @property {"es-us" | "en-us"} hreflang
 */

/**
 * makeLinkData generates an array of link data objects for the current page.
 * Primarily, this means it will report the alternate language version of the current page.
 * This returns either an array if the path has an alternate language version, or undefined if it does not.
 * If it's undefined, the Helmet component will assume the prop wasn't passed, and won't render anything.
 *
 * @param {string} path - The path of the current page.
 * @param {boolean} isSpanish - Whether the current page is in Spanish or not.
 * @returns {LinkDataObject[] | undefined} - Returns an array of link data objects if the path has an alternate language version.
 */
const makeLinkData = (path, isSpanish) => {
  const urlMap = isSpanish ? spanishToEnglish : englishToSpanish;
  const altPath = urlMap[path] || "";

  return altPath
    ? [
        {
          rel: "alternate",
          href: "https://www.wafdbank.com" + path,
          hreflang: isSpanish ? "es-us" : "en-us"
        },
        {
          rel: "alternate",
          href: "https://www.wafdbank.com" + altPath,
          hreflang: isSpanish ? "en-us" : "es-us"
        }
      ]
    : undefined;
};

export default makeLinkData;
