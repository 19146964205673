import PropTypes from "prop-types";
/**
 * transformSchema should host all necessary operations that transform the schema object.
 * Currently, this is only the Blog change, but schemas are used in other pages too. Just not all of them.
 *
 * @param {object} schema
 * @returns {object} - Returns the transformed schema object.
 */
const transformSchema = (schema) => {
  if (!schema) return {};

  const schemaCopy = { ...schema };

  // Blogs have headlines. They used to have the suffix, so we are appending it here.
  if (schema.headline && !schema.headline.includes(" | WaFd Bank")) {
    schemaCopy.headline += " | WaFd Bank";
  }

  return schemaCopy;
};

transformSchema.propTypes = {
  schema: PropTypes.object
};

export default transformSchema;
